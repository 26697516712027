import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Result } from 'antd';

const ThankYouPage: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		// Extract the access token from the query parameters
		const queryParams = new URLSearchParams(location.search);
		const accessToken = queryParams.get('accessToken');

		if (accessToken) {
			// Call the backend API in the background
			const completeSignup = async () => {
				try {
					await fetch('/api/signup', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
						},
						body: JSON.stringify({ token: accessToken }),
					});
				} catch (error) {
					console.error('Signup failed:', error);
				}
			};

			completeSignup();
		}

		// Redirect to the login page immediately
		navigate('/login');
	}, [location.search, navigate]);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
			}}
		>
			<Result
				status="success"
				title="Thank You for Signing Up!"
				subTitle="Redirecting you to the login page..."
			/>
		</div>
	);
};

export default ThankYouPage;
