import { useEffect } from 'react';

const OutsetaLogin = () => {
	useEffect(() => {
		// Redirect to the specified URL
		window.location.href =
			'https://costallocationpro.outseta.com/auth?widgetMode=login#o-anonymous';
	}, []);

	return null; // No UI to render since it's a redirect
};

export default OutsetaLogin;
